<template>
  <validation-provider
    #default="{ errors }"
    name="deliverySituation"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      label="Situação da Entrega"
      label-for="deliverySituation"
    >
      <v-select
        id="deliverySituation"
        v-model="deliverySituation"
        :class="errors.length > 0 ? 'error-select' : ''"
        :options="deliverySituationOptions"
        :placeholder="'Situação da Entrega'"
        :disabled="readonly"
        :clearable="!readonly"
      >
        <template #option="{ label }">
          {{ label }}
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'deliverySituationValue',
    event: 'deliverySituationChange',
  },
  props: {
    deliverySituationValue: {
      type: [Object, Boolean],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      deliverySituationOptions: [
        { label: 'Liberada', value: 'idle' },
        { label: 'Em Auditoria', value: 'audit' },
        { label: 'Concluida', value: 'done' },
        { label: 'Declinada', value: 'declined' },
      ],
    }
  },
  computed: {
    deliverySituation: {
      get() {
        return this.deliverySituationValue
      },
      set(value) {
        const valueMap = {
          idle: { label: 'Liberada', value: 'idle' },
          audit: { label: 'Em Auditoria', value: 'audit' },
          done: { label: 'Concluida', value: 'done' },
          declined: { label: 'Declinada', value: 'declined' },
        }

        this.$emit('deliverySituationChange', valueMap[value] || value)
      },
    },
  },
  watch: {
    deliverySituationValue(newVal) {
      this.deliverySituation = newVal
    },
  },
  created() {
    this.$emit('ready', true)
  },
}
</script>

<style lang="scss">

.v-select {
  background-color: white;
}
</style>
