var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":"carrier","rules":{
    required: _vm.requiredField,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Transportadora","label-for":"carrier"}},[_c('vue-autosuggest',{class:errors.length > 0 ? 'error-select' : '',attrs:{"no-filter":"","suggestions":_vm.carriersOptions,"input-props":{
        id: 'carrier',
        ref: 'carrier',
        class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
        placeholder: 'Transportadora',
        readonly: _vm.readonly,
      },"limit":_vm.limitElements,"get-suggestion-value":_vm.getCarrierSelected},on:{"input":_vm.onInputCarrierChange,"selected":_vm.onSelectedCarrier,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return [_c('p',[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(_vm.carrierSearch),callback:function ($$v) {_vm.carrierSearch=$$v},expression:"carrierSearch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }