<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <delivery-filter
            :visible-columns="tableColumns"
            @filter-change="updateFilter"
            @columns-change="updateColumns"
            @ready="syncLoad.ready('filter')"
          />
        </b-col>
      </b-row>
      <b-row
        class="justify-content-center mt-1"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <delivery-table
            ref="refBiddingTable"
            :filter="filter"
            active
            @ready="syncLoad.ready('table')"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
} from '@mdi/js'

import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import DeliveryTable from '@/views/order/delivery/DeliveryTable.vue'
import DeliveryFilter from '@/views/order/delivery/DeliveryFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    SpinLoader,
    DeliveryTable,
    DeliveryFilter,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const filter = {
      page_size: 10,
      search: '',
    }

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('table')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const tableColumns = [
      {
        key: 'id',
        label: 'Nº Entrega',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'situation',
        label: 'Situação',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'order__contract__bidding__client__name',
        label: 'Órgão',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'order__contract__bidding__trade_number',
        label: 'Nº Pregão',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'company__name_fantasy',
        label: 'Empresa',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'freight_estimated',
        label: 'Frete Estimado',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'freight_real',
        label: 'Frete Realizado',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'total_value',
        label: 'Valor total',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'carrier__name',
        label: 'Transportadora',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'date_delivery',
        label: 'Prazo de Entrega',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'status__name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'invoicing_delivery_date',
        label: 'Data de Entrega',
        visible: false,
        fixedColumn: false,
      },
    ]

    return {
      isLoading,
      filter,
      syncLoad,
      tableColumns,
    }
  },
  methods: {
    updateColumns(val) {
      this.$refs.refBiddingTable.refreshColumns(val)
    },
    updateFilter(newFilter) {
      this.filter = { ...this.filter, ...newFilter }
    },
  },
  setup() {
    return {
      mdiPlus,
    }
  },
}
</script>
