<template>
  <validation-provider
    #default="{ errors }"
    name="carrier"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      label="Transportadora"
      label-for="carrier"
    >
      <vue-autosuggest
        v-model="carrierSearch"
        no-filter
        :suggestions="carriersOptions"
        :input-props="{
          id: 'carrier',
          ref: 'carrier',
          class: errors.length > 0 ? 'form-control is-invalid' : 'form-control',
          placeholder: 'Transportadora',
          readonly: readonly,
        }"
        :limit="limitElements"
        :class="errors.length > 0 ? 'error-select' : ''"
        :get-suggestion-value="getCarrierSelected"
        @input="onInputCarrierChange"
        @selected="onSelectedCarrier"
        @blur="onBlur"
      >
        <template slot-scope="{suggestion}">
          <p>{{ suggestion.item.name }}</p>
        </template>
      </vue-autosuggest>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>
<script>

import {
  VueAutosuggest,
} from 'vue-autosuggest'

import {
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    VueAutosuggest,
    ValidationProvider,
  },
  model: {
    prop: 'carrierValue',
    event: 'carrierChange',
  },
  props: {
    carrierValue: {
      type: [Object, String],
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const carriersOptions = []
    return {
      limitElements: 20,
      carriersOptions,
      required,
      carrierSearch: '',
    }
  },
  computed: {
    carrier: {
      get() {
        return this.carrierValue
      },
      set(value) {
        this.$emit('carrierChange', value)
      },
    },
  },
  watch: {
    carrierValue: function updateCarrier(carrier) {
      if (carrier) {
        this.carrierSearch = this.getCarrierSelected({ item: carrier })
      } else {
        this.carrierSearch = ''
      }
    },
  },
  created() {
    if (this.carrierValue) {
      this.carrierSearch = this.getCarrierSelected({ item: this.carrierValue })
    }
    this.$emit('ready', true)
  },
  methods: {
    onSelectedCarrier(selected) {
      if (selected === '' || selected === undefined) {
        return
      }
      this.carrier = selected.item
      this.$emit('change', selected.item)
    },
    getCarrierSelected(suggestion) {
      return `${suggestion.item.name}`
    },
    onInputCarrierChange(text) {
      syslic
        .transport
        .fetchTransportList({
          search: text,
          p: 1,
          page_size: this.limitElements,
        })
        .then(response => {
          this.carriersOptions = [{
            data: response.data.results,
          }]
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados.',
              text: 'Não foi possível ler os dados do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onBlur() {
      if (this.carrier) {
        const carrierSelected = this.getCarrierSelected({ item: this.carrier })
        if (this.carrierSearch !== carrierSelected) {
          this.carrier = ''
        }
      } else {
        this.carrierSearch = ''
      }
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
